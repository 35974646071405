<script setup lang="ts">
import Carousel from '@/components/Carousel.vue';
import TheCard from '@/components/mode-list/TheCard.vue';
import { computed, getCurrentInstance, h, onBeforeMount, onMounted, provide, ref } from 'vue';
import { createI18n, I18nInjectionKey } from 'vue-i18n';

import type { Config } from '@/types/editor';
import type { Product } from '@/types/mode-list-products';

import { useEditor } from '@/composables/useEditor';
import useLocale from '@/composables/useLocale';
import useModeListData from '@/composables/useModeListData';
import de from '@/locales/de';
import en from '@/locales/en';
import es from '@/locales/es';
import fr from '@/locales/fr';

const props = defineProps<{
  locale?: 'en-GB' | 'fr-FR' | 'de-DE' | 'es-ES';
  config?: string;
  apikey: string;
}>();
const { setupCurrentLocale } = useLocale();
const { getProductsList, setApiKey, formatToProducts, woodyConfig } = useModeListData();
const { configToFilter } = useEditor();

const prefixUrl = computed(() => woodyConfig.value?.prefixUrl ?? null);

const $root = ref<HTMLElement>();
const products = ref<Array<Product>>([]);
const css = ref('');
const blockCLS = ref(true);
const renderStyle = computed(() => {
  return h('style', css.value);
});
const config = ref<Config>();

const timeout = 0;
function updateStyles() {
  blockCLS.value = true;
  const selector = process.env.PROD ? '[data-source="myapp"]' : 'style';
  clearTimeout(timeout);
  setTimeout(() => {
    const styles = Array.from(document.querySelectorAll(selector));
    css.value = styles
      .map(it => it.textContent ?? '')
      .join('');
    blockCLS.value = false;
  }, 25);
}

setupCurrentLocale(props.locale ?? 'fr-FR');
const i18n = createI18n({
  legacy: false, // must set to `false` for composition API
  locale: props.locale ?? 'fr-FR',
  fallbackLocale: 'fr-FR',
  messages: {
    en,
    fr,
    es,
    de,
  },
});
i18n.install(getCurrentInstance()!.appContext.app);
provide(I18nInjectionKey, i18n);

onBeforeMount(async () => {
  updateStyles();
  if (props.config == null) {
    throw new Error('No config provided for carousel.');
  }
  setApiKey(props.apikey);
  config.value = JSON.parse(props.config);
});

onMounted(async () => {
  if (config.value == null) {
    throw new Error('Carousel config is null !');
  }
  const result = await getProductsList({
    size: 100,
    metrics: [
      {
        type: 'taxonomyCount',
        taxonomyTypes: [
          'TYPES',
          'THEMES',
          'PLACES',
        ],
      },
    ],
    properties: [{ type: 'rating' }, { type: 'nextSessionDate' }, { type: 'product_enrichment_by_distributor' }],
    filters: configToFilter(config.value),
    sorts: [{ type: 'next_session' }],
    selections: [],
  });
  products.value = formatToProducts(result.data);
});
</script>

<template>
  <Carousel v-if="!blockCLS" ref="$root" style="min-height: 450px;" :items="products" :min-item-width="300" :max-item-width="350">
    <template #item="{ item }">
      <TheCard style="height: 99%" :product="item" :responsive-row="false" :prefix-url="prefixUrl" />
    </template>
  </Carousel>
  <renderStyle />
</template>

<style>
@import '@/assets/globals.css';
</style>
